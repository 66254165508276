<script setup lang="ts">
    import {useI18n} from 'vue-i18n';
    import useCpBem from '~/composables/useCpBem';
    import CpText from '~/components/cp/CpText/CpText.vue';

    defineProps<{
        actionDescription?: boolean
        email?: boolean
    }>();

    const {t} = useI18n();
    const PHONES = [t('mexPhone'), t('gdlPhone'), t('mtyPhone')];

    const {b, em} = useCpBem('cp-card-contact');
</script>

<template>
    <div :class="b">
        <CpText :class="em(`text`,{bottom: true})" variant="body-bold">
            {{ email ? t('contact') : t('callToUs') }}
        </CpText>
        <CpText
            v-for="(phone, number) in PHONES"
            :key="phone"
            :class="em(`text`,{bottomNumbers: number!== (PHONES.length-1)})"
            variant="body-regular"
            type="primary"
        >
            {{ phone }}
        </CpText>
        <CpText
            v-if="actionDescription"
            :class="em(`text`,{top: true})"
            variant="body-regular"
            type="neutral"
        >
            {{ t('officeHours') }}
        </CpText>
        <CpText
            v-if="email"
            :class="em(`text`,{top: true})"
            variant="body-regular"
            type="primary"
        >
            {{ t('emailInfoCyberPuerta') }}
        </CpText>
    </div>
</template>

<style scoped lang="scss">
    .cp-card-contact {
        border: 1px solid var(--color-neutral-extra-light);
        border-radius: 8px;
        padding: 15px 16px;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        text-align: center;
        &__text {
            &--bottom {
                margin-bottom: 8px;
            }
            &--top {
                margin-bottom: 0;
                margin-top: 8px;
            }
            &--bottom-numbers {
                margin-bottom: 4px;
            }
        }
        @media (max-width: 360px) {
            width: 176px;
        }
        @media (min-width: 361px) {
            width: 244px;
        }
    }
</style>
<i18n locale="es-US">
  mexPhone: 'MEX (55) 4737 1360'
  gdlPhone: 'GDL (33) 4737 1360'
  mtyPhone: 'MTY (81) 4737 1360'
  officeHours: 'L-V 9:00 - 18:00'
  emailInfoCyberPuerta: "info{'@'}cyberpuerta.mx"
  callToUs: 'Llámanos al:'
  contact: 'Contacto'
</i18n>
